<template>
  <!-- 签约并生成合同页 -->
  <div v-if="orderInfo.id" style="height: 100%" ref="load">
    <div style="height: calc(100% - 70px); overflow: auto">
      <div class="customize-detail">
        <div class="zt-block">
          <el-row type="flex" class="main-header" align="middle">
            <el-col :span="4">
              <span class="title">订单信息</span>
            </el-col>
          </el-row>
          <el-row>
            <el-col class="info-header-item">
              <el-row type="flex" justify="space-between">
                <el-col>对方应答信息</el-col>
                <el-col style="text-align: right">
                  <el-button
                    type="primary"
                    round
                    size="mini"
                    @click="
                      goto(
                        '/want/wantFollow/want/detail/' + $route.query.wantId
                      )
                    "
                    >查看原需求信息</el-button
                  >
                </el-col>
              </el-row>
            </el-col>
            <el-col class="info-body-item">
              <el-row class="detail">
                <el-col class="detail-content">
                  <el-row type="flex">
                    <el-col :span="8" class="detail-content-item">
                      <el-row type="flex">
                        <div class="image-wrap">
                          <img :src="imgUrl" width="106px" />
                        </div>
                        <div class="company-info">
                          <div class="company-info-main">
                            {{ offers ? offers.comp.name : "" }}
                          </div>
                          <div class="company-info-sub">
                            <el-button
                              @click.stop="
                                viewIntro(offers ? offers.comp.id : '')
                              "
                              style="color: #5074ee"
                              type="text"
                              >查看公司详情资质 ></el-button
                            >
                          </div>
                        </div>
                      </el-row>
                    </el-col>
                    <el-col
                      :span="4"
                      class="detail-content-item"
                      v-if="orderInfo.type === 'gaikuang'"
                    >
                      <DesignType :info="orderInfo"></DesignType>
                    </el-col>
                    <el-col :span="4" class="detail-content-item">
                      <el-row>
                        <el-col class="detail-content-info">最早交货</el-col>
                        <el-col class="detail-content-value">{{
                          (offers ? offers[orderInfo.type].giveDate : "")
                            | moment("YYYY-MM-DD")
                        }}</el-col>
                      </el-row>
                    </el-col>
                  </el-row>
                </el-col>
              </el-row>
            </el-col>
          </el-row>
        </div>
        <PayType></PayType>
        <div class="zt-block">
          <el-row>
            <el-col class="info-header-item">支付金额</el-col>
            <el-col class="info-body-item">
              <el-row>
                <el-col>
                  <span class="label">应付金额:</span>
                  <span
                    class="value highlight"
                    v-if="offers && offers[orderInfo.type].price"
                    >¥{{ offers[orderInfo.type].price | priceFilt }}</span
                  >
                </el-col>
              </el-row>
            </el-col>
          </el-row>
        </div>
        <div v-if="offers">
          <ContractInfo
            :contractInfo="offers"
            :type="orderInfo.type"
          ></ContractInfo>
        </div>
      </div>
    </div>
    <div class="creatOrder-button">
      <el-button
        v-if="offers && offers.orderId"
        @click="goOrder"
        size="mini"
        type="primary"
        >查看订单</el-button
      >
      <el-button
        v-else
        @click="submitForm()"
        size="mini"
        type="primary"
        :disabled="!(offers && offers.contract)"
        >生成订单并签约</el-button
      >
    </div>

    <el-dialog
      width="500px"
      :title="popTitle"
      :visible.sync="popVisible"
      :destroy-on-close="true"
    >
      <div v-if="popTitle == '签约'">
        <Sign
          :popTitle="popTitle"
          @data="refreshItems"
          @signState="signState"
          @hide="hide"
        ></Sign>
      </div>
      <div v-if="popTitle == '遇到问题'">
        <SignBack :popTitle="popTitle" @hide="popVisible = false"></SignBack>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { Loading } from "element-ui";
import { IMG_URL_PRE } from "@/config";
import {
  getRequireId,
  requireOfferId,
  requireOfferList,
  dayangOrder,
  gaikuangOrder,
} from "@/service/want";
import { goSign, contractAddr } from "@/service/contract";
import Sign from "@/components/sign/sign.vue";
import SignBack from "@/components/sign/signBack.vue";
import PayType from "../components/PayType";
import DesignType from "../components/DesignType";
import ContractInfo from "../components/ContractInfo";
export default {
  components: {
    Sign,
    SignBack,
    PayType,
    DesignType,
    ContractInfo,
  },
  computed: {
    imgUrl() {
      return this.offers ? IMG_URL_PRE + this.offers.comp.logo : "";
    },
  },
  data() {
    return {
      orderInfo: {
        createdAt: "",
        code: "",
        offer: {},
        contract: true,
        tradeOrder: {},
      },
      contractInfo: null,
      popTitle: "",
      popVisible: false,
      item: null,
      offers: null,
      rules: {
        tpl_id: [{ required: true, message: "请选择合同", trigger: "blur" }],
      },
      ruleForm: {
        tpl_id: null,
      },
      tpls: [],
    };
  },
  created() {
    this.refreshItems();
  },
  methods: {
    goOrder() {
      this.$router.push({
        path: "/factory/demandOrder",
        query: {
          type:
            this.orderInfo.type === "dayang" ? 0 : this.orderInfo.type === "gaikuang" ? 1 : null,
        },
      });
    },
    goto(link) {
      this.$router.push(link);
    },
    refreshItems() {
      getRequireId(this.$route.query.wantId)
        .then((rst) => {
          this.orderInfo = rst;
          this.offerList(this.orderInfo);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    offerList(item) {
      requireOfferList(item.id)
        .then((rst) => {
          if (rst.rows.length > 0) {
            this.offers = rst.rows.find(
              (item) => item.id === this.$route.query.id
            );
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    submitForm() {
      this.gotoSign();
      // if (this.$store.state.loginUser.comp.cert_stat != 2) {
      //   this.$message.error("企业未认证");
      //   return;
      // } else {
      // }
    },
    gotoSign() {
      this.$confirm("确认是否发起签约?", "温馨提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        closeOnClickModal: false,
        type: "warning",
      })
        .then(() => {
          if (this.orderInfo.type === "gaikuang") {
            gaikuangOrder({
              offerId: this.$route.query.id,
            })
              .then((rst) => {
                this.going(rst.tradeOrder.contract_no);
              })
              .catch((err) => {
                this.$message.error(err.message);
                console.log(err);
              });
          } else if (this.orderInfo.type === "dayang") {
            dayangOrder({
              offerId: this.$route.query.id,
            })
              .then((rst) => {
                this.going(rst.tradeOrder.contract_no);
              })
              .catch((err) => {
                console.log(err);
              });
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "取消签约",
          });
        });
    },
    going(contract_no, transaction_no) {
      this.loading = Loading.service({
        fullscreen: true,
        text: "拼命加载中....",
        target: this.$refs.load,
      });
      contractAddr(contract_no)
        .then((rst) => {
          if (rst && rst.url) {
            this.sign(rst.url);
          }
        })
        .catch(() => {
          this.loading.close();
        });
    },
    sign(url) {
      this.loading.close();
      if (url) window.open(url);
      this.signState("签约");
    },
    hide() {
      this.popVisible = false;
      this.$message.success("状态已更新");
    },
    signState(text) {
      this.popTitle = text;
      this.popVisible = true;
    },
  },
};
</script>
<style scoped src="../style.css"></style>
<style scoped>
.zt-block {
  box-shadow: none;
}
.zt-block-head {
  font-size: 18px;
  font-weight: 400;
  color: #3b3b3b;
  padding: 15px 30px 15px 30px;
  line-height: 25px;
}
.item {
  padding: 20px 30px;
  font-size: 14px;
  border-bottom: 1px solid rgba(230, 230, 230, 1);
}
.item-right > div {
  font-size: 16px;
  color: #808080;
  line-height: 22px;
  margin-bottom: 10px;
}
.item-right > div > div:first-child {
  width: 80px;
  color: #b3b3b3;
  text-align: left;
  margin-right: 10px;
}
.list-desc {
  padding: 20px 30px;
  color: #4d4d4d;
  border-bottom: 1px solid rgba(230, 230, 230, 1);
}
.list-desc > div {
  margin-bottom: 15px;
}
.list-desc > div:last-child {
  margin-bottom: 0px;
}
.list-desc > div > div:first-child {
  width: 95px;
  font-size: 14px;
  color: #808080;
  line-height: 22px;
  margin-right: 10px;
}

.zt-block >>> .el-form-item__error {
  left: 14px;
}

.creatOrder-button {
  padding: 20px 30px;
  background: #fff;
  border: 1px solid rgba(230, 230, 230, 1);
}
</style>
<style lang="less" scoped>
.customize-detail {
  .main-header {
    height: 53px;
    background: #fafaff;
    border: 1px solid #dbe1f6;
    padding-left: 20px;

    .title {
      font-weight: 600;
      color: #3b3b3b;
    }
  }

  .info-header-item {
    height: 50px;
    line-height: 50px;
    padding: 0 20px;
    border-bottom: 1px solid #f5f5f5;

    .design {
      font-size: 12px;
      color: #808080;
    }
  }

  .info-body-item {
    padding: 10px 20px;
    // background-color: #000;

    .link {
      color: #0091ff;
    }

    .label {
      font-size: 14px;
      font-weight: 500;
      color: #808080;
      margin-right: 10px;
    }

    .works {
      display: inline-block;
      // vertical-align: super;
      margin-right: 10px;
    }

    .value {
      font-size: 16px;
      font-weight: 400;
      color: #4d4d4d;

      &.highlight {
        color: #f66f6a;
      }

      &.stress {
        color: #292929;
      }
    }

    .commit-item {
      .danger {
        text-align: right;
        color: #f66f6a;
        .btn {
          &:hover {
            cursor: pointer;
          }
        }
      }
    }

    .detail-content-item {
      .image-wrap {
        width: 106px;
        margin-right: 20px;
      }
    }
  }
}
</style>
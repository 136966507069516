<template>
  <div class="zt-block">
    <el-row>
      <el-col class="info-header-item">合同信息</el-col>
      <el-col class="info-body-item">
        <el-row>
          <el-col v-if="type && contractInfo.contract">
            <el-button type="text" @click="viewContract">
              <i class="iconfont icon-PDF1"></i>
              {{type | contractFilt}}合同
            </el-button>
          </el-col>
          <el-col v-else style="height:100px;text-align:center;line-height:100px;">暂无合同,等待对方上传</el-col>
        </el-row>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import { IMG_URL_PRE } from "@/config";
import { testHt } from "@/service/contract";
export default {
  props: {
    type: {
      type: String,
      default: ""
    },
    contractInfo: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    viewContract() {
      let obj = {};
      if (this.type === "gaikuang") {
        obj = {
          contract_title: "需求定制合同",
          content_file: `${IMG_URL_PRE}${this.contractInfo.contract.file}`,
          custom_cover_page: this.contractInfo.contract.customCoverPage
        };
      } else if (this.type === "dayang") {
        // 打样合同待验证
        obj = {
          contract_title: "打样合同",
          content_file: `${IMG_URL_PRE}${this.contractInfo.contract.file}`,
          custom_cover_page: this.contractInfo.contract.customCoverPage
        };
      }

      window.open(testHt(obj));
    }
  }
};
</script>
<style lang="less" scoped>
.info-header-item {
  height: 50px;
  line-height: 50px;
  padding: 0 20px;
  border-bottom: 1px solid #f5f5f5;
}

.info-body-item {
  padding: 10px 20px;
}
</style>